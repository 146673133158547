<template>
  <v-dialog
      v-model="dialog"
      max-width="800"
      scrollable
  >
    <v-card>
      <v-toolbar dark>
        <v-card-title class="text-h5">
          <span>{{ title }} Admin User</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row class="mt-2">
            <v-col cols="12" md="4">
              <v-text-field
                  outlined
                  dense
                  :error="$v.admin_user.first_name.$error"
                  v-model="admin_user.first_name"
              >
                <template v-slot:label>
                  First Name <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="errors.first_name">{{ errors.first_name[0] }}</span>
              <span class="text-danger" v-if="$v.admin_user.first_name.$error">This Field is required</span>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                  outlined
                  dense
                  :error="$v.admin_user.middle_name.$error"
                  v-model="admin_user.middle_name"
              >
                <template v-slot:label>
                  Middle Name
                </template>
              </v-text-field>
              <span class="text-danger" v-if="errors.middle_name">{{ errors.middle_name[0] }}</span>
              <span class="text-danger" v-if="$v.admin_user.middle_name.$error">This Field is required</span>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                  outlined
                  dense
                  :error="$v.admin_user.last_name.$error"
                  v-model="admin_user.last_name"
              >
                <template v-slot:label>
                  Last Name <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="errors.last_name">{{ errors.last_name[0] }}</span>
              <span class="text-danger" v-if="$v.admin_user.last_name.$error">This Field is required</span>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                  outlined
                  dense
                  :error="$v.admin_user.username.$error"
                  v-model="admin_user.username"
              >
                <template v-slot:label>
                  Username <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="errors.username">{{ errors.username[0] }}</span>
              <span class="text-danger" v-if="$v.admin_user.username.$error">This Field is required</span>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                  outlined
                  dense
                  type="email"
                  :error="$v.admin_user.email.$error"
                  v-model="admin_user.email"
              >
                <template v-slot:label>
                  Email <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="errors.email">{{ errors.email[0] }}</span>
              <span class="text-danger" v-if="$v.admin_user.email.$error">Invalid Email</span>
            </v-col>

            <v-col cols="12" md="6" v-if="! this.edit">
              <v-text-field
                  outlined
                  type="password"
                  dense
                  :error="$v.admin_user.password.$error"
                  v-model="admin_user.password"
              >
                <template v-slot:label>
                  Password <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="errors.password">{{ errors.password[0] }}</span>
              <span class="text-danger" v-if="!edit && !$v.admin_user.password.minLength">
                Password must have at least 6 characters.
              </span>
            </v-col>

            <v-col cols="12" md="6" v-if="! this.edit">
              <v-text-field
                  outlined
                  dense
                  type="password"
                  :error="$v.admin_user.password_confirmation.$error"
                  v-model="admin_user.password_confirmation"
              >
                <template v-slot:label>
                  Confirm Password <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="errors.password_confirmation">{{ errors.password_confirmation[0] }}</span>
              <span
                  class="text-danger"
                  v-if="!edit && !$v.admin_user.password_confirmation.sameAsPassword"
              >
                Password Must Match
              </span>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                  label="Mobile"
                  outlined
                  dense
                  :error="$v.admin_user.mobile.$error"
                  v-model="admin_user.mobile"
              ></v-text-field>
              <span class="text-danger" v-if="errors.mobile">{{ errors.mobile[0] }}</span>
              <span class="text-danger" v-if="$v.admin_user.mobile.$error">This Field is required</span>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                  label="Phone"
                  outlined
                  dense
                  :error="$v.admin_user.phone.$error"
                  v-model="admin_user.phone"
              ></v-text-field>
              <span class="text-danger" v-if="errors.phone">{{ errors.phone[0] }}</span>
              <span class="text-danger" v-if="$v.admin_user.phone.$error">This Field is required</span>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                  outlined
                  dense
                  :error="$v.admin_user.address.$error"
                  v-model="admin_user.address"
              >
                <template v-slot:label>
                  Address <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="errors.address">{{ errors.address[0] }}</span>
              <span class="text-danger" v-if="$v.admin_user.address.$error">This Field is required</span>
            </v-col>

            <v-col cols="12" md="4">
              <v-select
                  outlined
                  :items="user_type"
                  item-text="name"
                  item-value="name"
                  dense
                  :error="$v.admin_user.user_type.$error"
                  v-model="admin_user.user_type"
              >
                <template v-slot:label>
                  User Type <span class="text-danger">*</span>
                </template>
              </v-select>
              <span class="text-danger" v-if="errors.user_type">{{ errors.user_type[0] }}</span>
              <span class="text-danger" v-if="$v.admin_user.user_type.$error">This Field is required</span>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                  v-model="admin_user.bank_account_no"
                  outlined
                  dense
              >
                <template v-slot:label>
                  Bank account number <span class="text-danger">*</span>
                </template>
              </v-text-field>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                  v-model="admin_user.bank_account_name"
                  outlined
                  dense
              >
                <template v-slot:label>
                  Bank account name <span class="text-danger">*</span>
                </template>
              </v-text-field>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                  v-model="admin_user.bank_branch"
                  outlined
                  dense
              >
                <template v-slot:label>
                  Bank branch <span class="text-danger">*</span>
                </template>
              </v-text-field>
            </v-col>

            <v-col cols="12" md="4">
              <v-file-input
                  v-model="admin_user.qr_code"
                  prepend-icon=""
                  prepend-inner-icon="mdi-file"
                  accept="image/png"
                  outlined
                  dense
              >
                <template v-slot:label>
                  Bank QR code
                </template>
              </v-file-input>
              <v-img
                  v-if="admin_user.bank_qr_code_path"
                  :src="admin_user.bank_qr_code_path.real"
                  max-width="200"
                  max-height="200"
              ></v-img>
            </v-col>

            <v-col cols="12" md="4" v-if="currentUser.access_type == 'head'">
              <v-select
                  outlined
                  :items="access_types"
                  item-text="name"
                  item-value="value"
                  @change="handleAccessType"
                  dense
                  :error="$v.admin_user.access_type.$error"
                  v-model="admin_user.access_type"
              >
                <template v-slot:label>
                  Access Type <span class="text-danger">*</span>
                </template>
              </v-select>
              <span class="text-danger" v-if="errors.access_type">{{ errors.access_type[0] }}</span>
              <span class="text-danger" v-if="$v.admin_user.access_type.$error">This Field is required</span>
            </v-col>

            <v-col cols="4" v-if="branch_flag && currentUser.access_type == 'head'">
              <v-select
                  outlined
                  dense
                  v-model="admin_user.branch_id"
                  :error="$v.admin_user.branch_id.$error"
                  :items="ourBranches"
                  item-text="name"
                  item-value="id"
              >
                <template v-slot:label>
                  Branch <span class="text-danger">*</span>
                </template>
              </v-select>
              <span class="text-danger" v-if="errors.branch_id">{{ errors.branch_id[0] }}</span>
              <span class="text-danger" v-if="$v.admin_user.branch_id.$error">This Field is required</span>
            </v-col>

            <v-col cols="12" md="4">
              Status
              <v-switch
                  v-model="admin_user.is_active"
                  :error="$v.admin_user.is_active.$error"
                  :label="admin_user.is_active ? 'Active': 'Inactive'"
              ></v-switch>
              <span class="text-danger" v-if="$v.admin_user.is_active.$error">This Field is required</span>
              <span class="text-danger" v-if="errors.is_active">{{ errors.is_active[0] }}</span>
            </v-col>

            <v-col cols="12" md="4">
              <v-switch
                  v-model="admin_user.is_mfa_enabled"
                  label="Is MFA Enabled ?"
                  :disabled="disableMfa"
              ></v-switch>
            </v-col>

            <v-col cols="12" md="4">
              <v-switch
                  v-model="admin_user.is_email_authentication_enabled"
                  label="Email Authentication Enabled ?"
                  :disabled="disableEmailAuthentication"
              ></v-switch>
            </v-col>
          </v-row>

          <v-row v-if="! edit">
            <v-col cols="12" sm="12" md="12">
              <v-checkbox v-model="admin_user.send_email">
                <template v-slot:label>
                  <span class="text-left font-weight-medium">
                    Send Password in email upon completion
                  </span>
                </template>
              </v-checkbox>
            </v-col>
            <v-col cols="12" v-if="admin_user.send_email">
              <v-combobox
                  v-model="admin_user.group_email"
                  prepend-inner-icon="mdi-send"
                  hide-selected outlined dense deletable-chips
                  multiple
                  small-chips
              >
                <template v-slot:label>
                  Emails <span class="text-danger">*</span>
                </template>
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        Press <kbd>enter</kbd> to create a new Email
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-combobox>
              <span class="text-danger" v-if="$v.admin_user.group_email.$error">This Field is required</span>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            text
            @click="closeDialog">
          Cancel
        </v-btn>

        <v-btn v-if="checkIsAccessible('admin-user', 'create') || checkIsAccessible('admin-user', 'edit')"
               class="btn btn-primary"
               @click="createOrUpdate()"
               :loading="loading"
        >
          {{edit ? "Update" : "Save"}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {email, minLength, required, requiredIf, sameAs} from "vuelidate/lib/validators";
import AdminUserService from "@/services/admin-user/AdminUserService";
import RoleService from "@/services/user/role/RoleService";

const admin_user = new AdminUserService();
const roleService = new RoleService();

export default {
  validations() {
    return {admin_user: this.adminUserRules};
  },
  computed: {
    disableEmailAuthentication() {
      if (this.edit) {
        if (this.admin_user.is_email_authentication_enabled == 1) {
          return false;
        }
        if (this.admin_user.is_email_authentication_enabled == 0) {
          return true;
        }
      }
      return true;
    },
    disableMfa() {
      if (this.edit) {
        if (this.admin_user.is_mfa_enabled == 1) {
          return false;
        }
        if (this.admin_user.is_mfa_enabled == 0) {
          return true;
        }
      }
      return true;
    },
    adminUserRules() {
      let rules = {};

      if (!this.edit) {
        rules = {
          first_name: {required},
          middle_name: {},
          last_name: {required},
          password: {required, minLength: minLength(6)},
          password_confirmation: {required, sameAsPassword: sameAs('password')},
          mobile: {},
          phone: {},
          username: {required},
          email: {required, email},
          address: {required},
          access_type: {required},
          user_type: {required},
          bank_account_no: {required},
          bank_account_name: {required},
          bank_branch: {required},
          branch_id: {
            required: requiredIf(function () {
              return this.admin_user.access_type == 'branch';
            })
          },
          group_email: {
            required: requiredIf(function () {
              return this.admin_user.send_email;
            })
          },
          is_mfa_enabled: {required},
          is_email_authentication_enabled: {required},
          is_active: {required}
        }
      }
      if (this.edit) {
        rules = {
          first_name: {required},
          middle_name: {},
          last_name: {required},
          password: {},
          password_confirmation: {},
          mobile: {},
          phone: {},
          username: {required},
          email: {required, email},
          address: {required},
          access_type: {required},
          user_type: {required},
          branch_id: {
            required: requiredIf(function () {
              return this.admin_user.access_type == 'branch';
            })
          },
          is_active: {required}
        }
      }
      return rules;
    }
  },
  data() {
    return {
      title: "",
      edit: false,
      errors: [],
      dialog: false,
      loading: false,
      emails: [],
      access_types: [
        {
          'name': 'Branch',
          'value': 'branch'
        },
        {
          'name': 'Head',
          'value': 'head'
        },
      ],
      user_type: [
        {
          'name': 'Consultant',
          'value': 'consultant'
        },
        {
          'name': 'Receptionist',
          'value': 'receptionist'
        },
      ],
      branch_flag: false,
      admin_user: {
        first_name: '',
        middle_name: '',
        last_name: '',
        password: '',
        password_confirmation: '',
        mobile: '',
        phone: '',
        username: '',
        email: '',
        address: '',
        access_type: '',
        user_type: '',
        bank_account_no: '',
        bank_account_name: '',
        bank_branch: '',
        qr_code: null,
        branch_id: null,
        is_mfa_enabled: 0,
        is_email_authentication_enabled: 0,
        is_active: 1,
        send_email: 0,
        group_email: []
      },
    }
  },
  methods: {
    getUserTypes(){
      roleService.all().then(response=>{
        this.user_type = response.data.data
      })
    },
    closeDialog() {
      this.dialog = false;
      this.errors = [];
      this.resetForm();
    },
    openDialog() {
      this.$v.$reset();
      this.dialog = true;
    },
    createAdminUser() {
      this.resetForm();
      this.openDialog();
      this.edit = false;
      this.admin_user.access_type = '';
      this.title = "Add";
      if (this.currentUser.access_type == 'branch') {
        this.admin_user.access_type = 'branch';
        this.admin_user.branch_id = this.currentUser.branch_id;
      }
    },
    editAdminUser(item) {
      this.openDialog();
      this.edit = true;
      this.admin_user = item;
      this.admin_user.qr_code = null;
      if (this.admin_user.branch_id) {
        this.branch_flag = true;
      }
      this.title = "Edit";
      if (this.currentUser.access_type == 'branch') {
        this.admin_user.access_type = 'branch';
        this.admin_user.branch_id = this.currentUser.branch_id;
      }
    },
    convertToFormData() {
      let formData = new FormData();
      for (let key in this.admin_user) {
        if (key === "qr_code" && this.admin_user[key] != null && this.admin_user[key] != undefined) {
          formData.append('qr_code', this.admin_user[key]);
        } else {
          if(key !== "qr_code") {
            let value = this.admin_user[key] ? this.admin_user[key] : '';
            formData.append(key, value);
          }
        }
      }
      return formData;
    },
    createOrUpdate() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      } else {
        let formData = this.convertToFormData();
        if (!this.edit) this._create(formData);
        else this.update(formData);
      }
    },
    handleAccessType() {
      this.admin_user.branch_id = null;
      this.branch_flag = this.admin_user.access_type == 'branch';
    },

    _create(formData) {
      this.loading = true;
      admin_user
          .create(formData)
          .then(response => {
            this.$snotify.success("Admin User created successfully");
            this.resetForm();
            this.closeDialog();
            this.loading = false;
            this.$emit('refresh');
          })
          .catch((err) => {
            this.loading = false;
            this.errors = err.errors;
            this.$snotify.error("Oops something went wrong");
          });
    },
    update(formData) {
      this.loading = true;
      this.admin_user.password = null;
      this.admin_user.password_confirmation = null;
      admin_user
          .update(this.admin_user.id, formData)
          .then(response => {
            this.$snotify.success("Admin User updated successfully");
            this.resetForm();
            this.closeDialog();
            this.loading = false;
            this.$emit('refresh');
          })
          .catch((err) => {
            this.loading = false;
            this.errors = err.response.data.errors;
            this.$snotify.error("Oops something went wrong");
          });
    },
    resetForm() {
      this.admin_user = {
        first_name: '',
        middle_name: '',
        last_name: '',
        password: '',
        password_confirmation: '',
        mobile: '',
        phone: '',
        username: '',
        email: '',
        address: '',
        access_type: null,
        user_type: '',
        bank_account_no: '',
        bank_account_name: '',
        bank_branch: '',
        qr_code: null,
        branch_id: null,
        is_mfa_enabled: 0,
        is_email_authentication_enabled: 0,
        is_active: 1,
        send_email: 0,
        group_email: []
      }
          this.branch_flag = false;
      this.errors = [];
      this.$v.$reset();
    }
  },
  mounted() {
    this.getAllBranches();
    this.getUserTypes();
    if(this.currentUser && this.currentUser.access_type == 'branch') {
      this.admin_user.branch_id = this.currentUser.branch_id;
    }
  }
}
</script>