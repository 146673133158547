<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>
                  Admin User
                </h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard</router-link>
                  \ Admin User
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn v-if="checkIsAccessible('admin-user', 'create')"
                         @click="createAdminUser()"
                         class="btn btn-primary font-weight-bolder font-size-sm">
                    <i class="fa fa-plus"></i>
                    Add Admin User
                  </v-btn>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                      label="Search by Name, Email..."
                      v-model="search.name"
                      outlined
                      dense
                      v-on:keyup.enter="searchAdminUsers()"
                      @input="search.name = $event !== null ? $event : ''"
                      clearable
                  ></v-text-field>
                </v-col>

                <v-col class="d-flex" cols="12" sm="12" md="4">
                  <v-select
                      :items="search_statuses"
                      v-model="search.status"
                      label="Status"
                      item-text="name"
                      item-value="value"
                      v-on:keyup.enter="searchAdminUsers()"
                      @input="search.status = $event !== null ? $event : ''"
                      outlined
                      clearable
                      dense
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="12" md="2">
                  <v-btn
                      @click="searchAdminUsers()"
                      :loading="loading"
                      class="mt-1 btn btn-primary text-white"
                  >
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>

            </div>

            <div class="table-responsive">
              <v-skeleton-loader
                  type="table-thead"
                  v-if="loading"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="loading"
                  type="table-row-divider@25"
              >
              </v-skeleton-loader>
              <table class="table">
                <thead>
                <tr class="text-left">
                  <th>Full Name</th>
                  <th>Email</th>
                  <th>Branch</th>
                  <th>Status</th>
                  <th class="pr-3 text-center">Action</th>
                </tr>
                </thead>
                <tbody>
                <template>
                  <tr v-for="(item, index) in admin_users" :key="index">
                    <td class="px-2">
                      <a @click="viewProfile(item.id)" class="text-primary font-weight-bolder d-block font-size-lg">
                        {{ item.full_name }}
                      </a>
                    </td>

                    <td class="px-2">
                      <span class="font-weight-bolder text-dark-65 text-primary mb-1 font-size-lg">
                        {{ item.email }}
                      </span>
                    </td>

                    <td class="px-2">
                      <span class="font-weight-bolder text-dark-65 text-primary mb-1 font-size-lg">
                        {{ item.branch_name ? item.branch_name : 'N/A' }}
                      </span>
                    </td>

                    <td>
                        <span class="badge" v-bind:class="{ 'badge-success': item.is_active, 'badge-danger': !item.is_active }"
                        >{{ item.is_active ? 'Active' : 'Inactive' }}</span>
                    </td>

                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('admin-user', 'edit')">
                              <a @click="editAdminUser(item)" class="navi-link">
                                <span class="navi-icon">
                                  <v-icon color="blue darken-2">fas fa-pen</v-icon>
                                </span>
                                <span class="navi-text">Edit</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('admin-user', 'show')">
                              <a @click="viewProfile(item.id)" class="navi-link">
                                <span class="navi-icon">
                                    <v-icon color="blue darken-2">fas fa-user</v-icon>
                                </span>
                                <span class="navi-text">View Profile</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('admin-user', 'edit')">
                              <a @click="changePassword(item)" class="navi-link">
                                <span class="navi-icon">
                                    <v-icon color="blue darken-2">fas fa-key</v-icon>
                                </span>
                                <span class="navi-text">Change Password</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('admin-user', 'delete')">
                              <a class="navi-link" @click.prevent="deleteAdminUser(item.id)">
                                <span class="navi-icon">
                                    <v-icon color="red darken-2">fas fa-trash</v-icon>
                                </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr v-if="admin_users.length == 0">
                    <td colspan="5" class="text-center">
                      No items found
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
              <b-pagination
                  v-if="admin_users.length > 0"
                  class="pull-right mt-7"
                  @input="getAdminUsers"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  last-number
                  :disabled="loading"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>

      <create-and-update ref="create-and-update" @refresh="getAdminUsers"></create-and-update>

      <password-update ref="change-password" @refresh="getAdminUsers"></password-update>

    </div>
  </v-app>
</template>

<script>
import AdminUserService from "@/services/admin-user/AdminUserService";
import CreateAndUpdate from "./CreateAndUpdate";
import PasswordUpdate from "./PasswordUpdate";

const admin_user = new AdminUserService();

export default {
  components: {
    CreateAndUpdate, PasswordUpdate
  },
  data() {
    return {
      total: null,
      perPage: null,
      page: null,
      admin_users: [],
      loading: true,
      search: {
        name: '',
        status: ''
      },
      search_statuses: [
        {name: 'Active', value: 1},
        {name: 'Inactive', value: 0},
      ],
    }
  },
  methods: {
    searchAdminUsers() {
      this.getAdminUsers();
    },
    getAdminUsers() {
      admin_user
          .paginate(this.search, this.page)
          .then(response => {
            this.admin_users = response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
          })
          .catch((err) => {
          })
          .finally(() => {
            this.loading = false;
          });
    },
    createAdminUser() {
      this.$refs['create-and-update'].createAdminUser();
    },
    editAdminUser(item) {
      this.$refs['create-and-update'].editAdminUser(item);
    },
    changePassword(item) {
      this.$refs['change-password'].changePassword(item);
    },
    deleteAdminUser(userId) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            admin_user
                .delete(userId)
                .then((response) => {
                  this.getAdminUsers();
                  this.$snotify.success("Admin User Deleted Successfully.");
                })
                .catch(() => {});
          }
        },
      });
    },
    viewProfile(adminUserId) {
      this.$router.push(
          {
            name: 'admin-user-profile',
            params: {admin_user_id: adminUserId}
          }
      );
    }
  },
  mounted() {
    this.getAdminUsers();
  }
}
</script>
